import React from 'react';

import * as pcStyles from './BillingAddress.module.css';
import * as mpsStyles from './BillingAddress.mps.module.css';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });

const BillingAddress = ({ title, address }) => {
  if (!address) {
    return <div />;
  }
  return (
    <div className={styles.root}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.description}>{`${address.firstName} ${address.lastName}`}</div>
      {address.company && <div>{address.company}</div>}
      <div className={styles.description}>{address.street1}</div>
      {address.street2 && <div>{address.street2}</div>}
      <div
        className={styles.description}
      >{`${address.city}, ${address.state}, ${address.zip}`}</div>
      <div className={styles.description}>{address.country}</div>
    </div>
  );
};

export default BillingAddress;
