import { head } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../context/CartProvider';
import BillingAddress from '../../components/atoms/BillingAddress/BillingAddress';
import Button from '../../components/atoms/Button/Button';
import CurrencyFormatter from '../../components/atoms/CurrencyFormatter/CurrencyFormatter';
import DateFormatter from '../../components/atoms/DateFormatter/DateFormatter';
import Dialog from '../../components/atoms/Dialog/Dialog';
import Icon from '../../components/atoms/Icon/Icon';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import ReturnsForm from '../../components/organisms/ReturnsForm/ReturnsForm';
import { Center, Spinner, useToast, Flex } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import * as pcStyles from './orders.module.css';
import * as mpsStyles from './orders.mps.module.css';
import { storeStyle, isMadPawsStore } from '../../common/util';
import { useLocation } from '@reach/router';
import moment from 'moment';
import {
  OrderStatusCtn,
  ProgressContainer,
  Step,
  Circle,
  StatusCard,
  Title,
  Subtitle
} from 'styled/AccountOrders.styled';
import { getStorage } from 'helpers/general';

const stepsMed = [
  { title: 'Order Received', seconText: 'Checking your prescription' },
  { title: 'Prescription Check done', seconText: 'Currently dispensing medication' },
  { title: 'Order Dispensed', seconText: 'Awaiting courier pickup' },
  { title: 'Order Shipped', seconText: 'In transit' }
];

const stepsOTC = [
  { title: 'Order Received', seconText: '' },
  { title: 'Packing Order', seconText: '' },
  { title: 'Order Shipped', seconText: '' }
];

const ProgressTracker = ({ steps, order, isMed }) => {
  let currentStep = 0;
  if (isMed) {
    if (order.metadata?.fileInPhamacy) {
      currentStep = 1;
    }
    if (order.metadata?.dispensedAt) {
      currentStep = 2;
    }
  } else {
    if (order.metadata?.printedAt) {
      currentStep = 1;
    }
    // if (order.cin7Order?.dispatchedDate) {
    //   currentStep = 2;
    // }
  }
  if (order.dateShipped) {
    currentStep = 3;
  }
  return (
    <ProgressContainer>
      {steps.map((step, index) => (
        <Step key={index} active={index <= currentStep}>
          <div className={`step ${index === 0 ? 'first' : ''}  ${!isMed ? 'otc' : ''}`}>
            <Circle active={index <= currentStep} />
          </div>
          <div className="stepText">
            <span>{step.title}</span>
            {step.seconText && <Subtitle>{step.seconText}</Subtitle>}
          </div>
        </Step>
      ))}
    </ProgressContainer>
  );
};

const styles = storeStyle({ mpsStyles, pcStyles });

const fetchOrders = async ({ pageParam, orderId = 0 }) => {
  const token = getStorage('_loggedIn');
  if (!token) {
    return;
  }
  const apiUrl = `https://petchemist-nodejs-production.up.railway.app`;

  const response = await fetch(
    `${apiUrl}/v1/customer/orders?limit=5&page=${pageParam}&orderId=${orderId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization-BC': token
      }
    }
  );
  if (!response.ok) {
    throw new Error('Failed to fetch prescriptions');
  }
  const textResponse = await response.text();
  const data = await JSON.parse(textResponse);
  if (!data) {
    return;
  }
  return data.data;
};

const Orders = ({ customerId }) => {
  const [activatedId, setActivatedId] = useState(0);
  const [openReturns, toggleOpenReturns] = useState(false);
  const [reorderError, setReorderError] = useState(false);
  const cartCtx = useContext(CartContext);
  const addAllToCart = cartCtx && cartCtx.addAllToCart;
  const toast = useToast();
  const { hash } = useLocation();
  const {
    data,
    isFetching,
    isFetched,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: ['orders', customerId],
    queryFn: ({ pageParam = 1 }) => {
      const orderIdReq = hash ? hash.replace('#', '') : 0;
      const isValidOrderId = /^\d+$/.test(orderIdReq);
      return fetchOrders({ pageParam, orderId: isValidOrderId ? orderIdReq : 0 });
    },
    refetchOnWindowFocus: false,
    // enabled: false,
    getNextPageParam: (_, pages) => {
      if (pages.at(-1).length > 0) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const isMedication = products => {
    if (products && products.length !== 0) {
      const isMed = products.some(item =>
        item?.customFields?.some(
          field => field.name === 'Product Type' && field.value === 'Medication'
        )
      );
      return isMed;
    }
    return false;
  };

  const toggleViewOrder = order => () => {
    if (activatedId.id !== order.id) {
      setActivatedId(order.id);
    } else {
      setActivatedId(0);
    }
  };

  const getFirstProductImage = order => {
    if (order) {
      if (order.products && order.products?.length !== 0) {
        const image = head(order.products).image;
        return <img loading="lazy" src={image?.url} alt={image?.description} />;
      }
    }
    return null;
  };

  const reorder = (e, orderData) => {
    e.target.innerText = 'Adding...';
    addAllToCart(orderData.products)
      .then(response => {
        if (typeof window !== 'undefined') {
          window.location = '/confirm-order';
        }
      })
      .catch(e => {
        if ('title' in e && e.title.indexOf('does not have sufficient stock') > -1) {
          setReorderError(
            'One or more of the products in this order are currently out of stock. Please add products individually.'
          );
        } else if ('title' in e && e.title.indexOf('No products to add') > -1) {
          setReorderError(
            'There are no products above that can be added. For example, gift cards are not able to be reordered.'
          );
        } else {
          setReorderError(
            'An error occurred while trying to add the products from the order to cart. Please add products individually.'
          );
        }
      });
  };

  useEffect(() => {
    if (hash && isFetched) {
      const reqId = Number(hash.replace('#', ''));
      const element = document.getElementById(reqId);
      if (element) {
        element.scrollIntoView();
        setActivatedId(reqId);
      }
    }
  }, [hash, isFetched, data?.pages]);

  useEffect(() => {
    if (typeof hasNextPage !== 'undefined' && !hasNextPage) {
      toast({
        title: 'No more orders',
        description: 'You have no more orders to view',
        status: 'info',
        duration: 5000,
        isClosable: true,
        colorScheme: 'primary',
        position: 'top-right'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage]);

  const clearReorderDialog = () => {
    setReorderError(false);
  };

  function convertToSydneyTime(dateStr) {
    return moment
      .utc(dateStr)
      .add(11, 'hours')
      .format('DD MMM YYYY HH:mm:ss');
  }

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    setReorderError(false);
  };

  const cognitoFormsLink = () => {
    let cognitoLink =
      'https://www.cognitoforms.com/madpaws1/medicationorderprescriptiondetailscollection';
    if (data?.pages?.length && data?.pages[0]?.[0]) {
      const firstOrder = data?.pages[0]?.[0];
      const dataPrefill = {
        YourDetails: {
          Name: {
            First: firstOrder.billing_address?.first_name,
            Last: firstOrder.billing_address?.last_name
          },
          OrderNumber: firstOrder.id
        }
      };
      cognitoLink = `${cognitoLink}?entry=${encodeURIComponent(JSON.stringify(dataPrefill))}`;
    }
    return cognitoLink;
  };

  return (
    <div className={styles.root}>
      {!data ||
        (!isFetching && data.pages.length === 1 && data.pages?.at(-1)?.length === 0 && (
          <span>You have no orders</span>
        ))}

      {isFetching && !isFetchingNextPage ? (
        <Center py={8}>
          <Spinner size={'xl'} color="teal" />
        </Center>
      ) : (
        <>
          <h1 className={`${styles.heading} center-title`}>Orders</h1>
          <Flex className={styles.createAccount}>
            <Button level={'tertiary'} target type={'a'} href={cognitoFormsLink()}>
              UPLOAD PRESCRIPTION
            </Button>
          </Flex>
          <div className={`dataTable ${styles.dataTable}`}>
            <div className={styles.tableHeader}>
              <div data-orderid>
                <span>Order #</span>
              </div>
              <div>
                <span>Order Placed</span>
              </div>
              <div>
                <span>Last Update</span>
              </div>
              <div data-status>
                <span>Status</span>
              </div>
              <div data-toggle></div>
            </div>
            {data?.pages?.map((orders, index) => (
              <React.Fragment key={index}>
                {orders.map((order, index) => (
                  <React.Fragment key={index}>
                    {order.statusId > 0 && (
                      <div className={styles.tableRow} key={order.id} id={order.id}>
                        <div
                          className={styles.orderItem}
                          role="presentation"
                          onClick={toggleViewOrder(order)}
                        >
                          <div data-productimage>{getFirstProductImage(order)}</div>
                          <div data-orderid>
                            <div className={styles.bold}>Order #{order.id}</div>
                            <div className={styles.productDetail}>
                              {order.itemsTotal} product
                              {order.itemsTotal > 1 ? 's' : ''} totaling{' '}
                              <CurrencyFormatter
                                currency={order.currencyCode}
                                amount={order.totalIncTax}
                                ignoreTax={true}
                              />
                            </div>
                          </div>
                          <div data-orderplaced>
                            <div className={styles.bold}>Order Placed</div>
                            <DateFormatter date={order.dateCreated} />
                          </div>
                          <div data-lastupdated>
                            <div className={styles.bold}>Last Update</div>
                            <DateFormatter date={order.dateModified} />
                          </div>
                          <div data-status>
                            <div className={styles.bold}>Status</div>
                            <span>{order.customStatus ?? order.status}</span>
                          </div>
                          <div data-toggle className={activatedId === order.id ? styles.open : ''}>
                            <Icon symbol={isMadPawsStore() ? 'caretDownMps' : 'caretDown'} />
                          </div>
                        </div>

                        {activatedId === order.id && (
                          <>
                            <OrderStatusCtn>
                              <StatusCard>
                                <Title>Status</Title>
                                <ProgressTracker
                                  steps={isMedication(order.products) ? stepsMed : stepsOTC}
                                  order={order}
                                  isMed={isMedication(order.products)}
                                />
                              </StatusCard>
                            </OrderStatusCtn>
                            <div className={styles.orderDetail}>
                              <div className="show">
                                <div className={styles.orderBillingAddress}>
                                  <BillingAddress
                                    title="Ship to"
                                    address={order.shippingAddresses[0]}
                                  />
                                  <BillingAddress title="Bill to" address={order.billingAddress} />
                                </div>
                                <div className={styles.orderDetailTable}>
                                  {order.products?.map(product => {
                                    return (
                                      <div
                                        key={product.productId}
                                        className={styles.orderDetailRow}
                                      >
                                        <div>
                                          <img
                                            loading="lazy"
                                            src={product.image?.url}
                                            alt={product.image?.description}
                                          />
                                        </div>
                                        <div>
                                          <div className={styles.bold}>{product.name}</div>
                                          <div className={styles.quantity}>
                                            Quantity: {product.quantity}
                                          </div>
                                          {product.productOptions.length > 0 &&
                                            product.productOptions.map((option, optionIndex) => (
                                              <div className={styles.quantity} key={optionIndex}>
                                                {option.displayName}:{option.displayValue}
                                              </div>
                                            ))}
                                        </div>
                                        <div className={`flex-end ${styles.bold}`}>
                                          <CurrencyFormatter
                                            currency={order.currencyCode}
                                            amount={product.totalIncTax}
                                            ignoreTax={true}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                                {order.shipments.length > 0 ? (
                                  <div className={styles.shipments}>
                                    <div className={styles.shipmentTitle}>Shipments info:</div>
                                    <div>
                                      {order.shipments.map((shipment, i) => (
                                        <div
                                          className={styles.shipmentBody}
                                          key={`shipment-${shipment.id}`}
                                        >
                                          <div>
                                            <b>Courier Name:</b>{' '}
                                            {order.shippingAddresses[0]?.shippingMethod}
                                          </div>
                                          <div>
                                            <b>Date:</b> {convertToSydneyTime(shipment.dateCreated)}
                                          </div>
                                          <div>
                                            <b>Tracking:</b> {shipment.trackingNumber}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ) : null}
                                <div className={styles.summary}>
                                  <div className={styles.summaryActions}>
                                    <div className="flex-between">
                                      <Button
                                        level="ghost"
                                        type="button"
                                        onClick={e => reorder(e, order)}
                                      >
                                        {isMadPawsStore() ? 'Re-order' : 'Reorder'}
                                      </Button>
                                      <Dialog
                                        open={reorderError ? true : false}
                                        title="Unable to reorder"
                                        size="sm"
                                        hideBtnCancel
                                        disableBackdropClick
                                        onOk={() => clearReorderDialog()}
                                        onClose={(event, reason) => handleCancel(event, reason)}
                                      >
                                        {reorderError}
                                      </Dialog>
                                    </div>
                                    <Dialog
                                      title="Returns request"
                                      hideBtnOk={true}
                                      cancelBtnText="Close"
                                      open={openReturns}
                                      onCancel={() => toggleOpenReturns(false)}
                                    >
                                      <ReturnsForm orderDetails={order} />
                                    </Dialog>
                                  </div>
                                  <div className={styles.summaryInner}>
                                    <div>
                                      <span className={styles.normal}>Subtotal:</span>
                                      <span className={styles.bold}>
                                        <CurrencyFormatter
                                          currency={order.currencyCode}
                                          amount={order.subtotalIncTax}
                                          ignoreTax={true}
                                        />
                                      </span>
                                    </div>
                                    {order?.couponDiscount && order?.couponDiscount > 0 && (
                                      <div>
                                        <span className={styles.normal}>Coupon discount:</span>
                                        <span className={styles.bold}>
                                          <CurrencyFormatter
                                            currency={order.currencyCode}
                                            amount={order.couponDiscount}
                                            ignoreTax={true}
                                          />
                                        </span>
                                      </div>
                                    )}

                                    {order?.giftCertificateAmount &&
                                      order?.giftCertificateAmount > 0 && (
                                        <div>
                                          <span className={styles.normal}>Gift certificate:</span>
                                          <span className={styles.bold}>
                                            <CurrencyFormatter
                                              currency={order.currencyCode}
                                              amount={order.giftCertificateAmount}
                                              ignoreTax={true}
                                            />
                                          </span>
                                        </div>
                                      )}

                                    <div>
                                      <span className={styles.normal}>Shipping:</span>
                                      <span className={styles.bold}>
                                        <CurrencyFormatter
                                          currency={order.currencyCcode}
                                          amount={order.shippingCostIncTax}
                                          ignoreTax={true}
                                        />
                                      </span>
                                    </div>

                                    <div>
                                      <span className={styles.normal}>GST:</span>
                                      <span className={styles.bold}>
                                        <CurrencyFormatter
                                          currency={order.currencyCcode}
                                          amount={order.totalTax}
                                          ignoreTax={true}
                                        />
                                      </span>
                                    </div>

                                    <div>
                                      <span className={isMadPawsStore() && styles.total}>
                                        Grand Total:
                                      </span>
                                      <span className={styles.bold}>
                                        <CurrencyFormatter
                                          currency={order.currencyCcode}
                                          amount={order.totalIncTax}
                                          ignoreTax={true}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>

          {hasNextPage && (
            <Center textAlign={'center'}>
              <Button
                disabled={isFetchingNextPage}
                loading={isFetchingNextPage}
                type={'button'}
                level={'tertiary'}
                onClick={fetchNextPage}
              >
                View more
              </Button>
            </Center>
          )}
        </>
      )}
    </div>
  );
};

const OrdersOutput = () => (
  <AccountPageWrapper metaTitle="Pet Chemist Online - Order Status" title="Orders">
    <Orders />
  </AccountPageWrapper>
);

export default OrdersOutput;
