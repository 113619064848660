// extracted by mini-css-extract-plugin
export var bold = "orders-mps-module--bold--4fa2e";
export var createAccount = "orders-mps-module--createAccount--8773b";
export var dataTable = "orders-mps-module--dataTable--6c56a";
export var heading = "orders-mps-module--heading--2a0a7";
export var loaderContainer = "orders-mps-module--loaderContainer--3e98d";
export var normal = "orders-mps-module--normal--1bce0";
export var open = "orders-mps-module--open--4b3a5";
export var orderBillingAddress = "orders-mps-module--orderBillingAddress--3c83a";
export var orderDetail = "orders-mps-module--orderDetail--9bd7a";
export var orderDetailRow = "orders-mps-module--orderDetailRow--eb379";
export var orderDetailTable = "orders-mps-module--orderDetailTable--e3032";
export var orderItem = "orders-mps-module--orderItem--d9273";
export var paymentDetails = "orders-mps-module--paymentDetails--90c8f";
export var productDetail = "orders-mps-module--productDetail--8deb2";
export var quantity = "orders-mps-module--quantity--9db8c";
export var root = "orders-mps-module--root--1ca1f";
export var shipmentBody = "orders-mps-module--shipmentBody--800b2";
export var shipmentTitle = "orders-mps-module--shipmentTitle--8ae87";
export var shipments = "orders-mps-module--shipments--a79c9";
export var summary = "orders-mps-module--summary--80351";
export var summaryActions = "orders-mps-module--summaryActions--86e8d";
export var summaryInner = "orders-mps-module--summaryInner--b2ce6";
export var tableHeader = "orders-mps-module--tableHeader--b10f9";
export var tableRow = "orders-mps-module--tableRow--509f2";
export var total = "orders-mps-module--total--22ce7";