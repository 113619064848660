/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const OrderStatusCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #f8f8f8;
  border-top: 1px solid #c9d5da;
  padding-top: 10px;
  border-radius: 8px;
`;

export const StatusCard = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
  font-weight: bold;
  text-align: center;
  font-size: 14px;
`;

export const Subtitle = styled.p`
  font-size: 8px;
  text-align: center;
  color: gray;
  font-style: italic;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
`;

export const Step = styled.div`
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    text-align: center;
    margin: 0 0 5px 0;
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      width: 100%;
      height: 2px;
      background: ${props => (props.active ? '#005A7A' : '#c9d5da')};
      z-index: 1;
      transform: translateX(-50%);
    }
  }
  .otc::before {
    width: 146%;
  }
  @media screen and (max-width: 410px) {
    .otc::before {
      width: 130%;
    }
  }
  .first {
    &::before {
      display: none;
    }
  }
  min-width: 25%;
  margin-top: 20px;
  text-align: center;
  .stepText {
    line-height: 1.3;
    & > span {
      font-size: 14px;
    }
  }
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: ${props => (props.active ? '#005A7A' : 'white')};
  border: 2px solid ${props => (props.active ? '#005A7A' : '#ccc')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
